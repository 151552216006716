<template>
    <section class="content">
        <div class="container-fluid">
            <div class="rowx">
                <div class="text-center text-danger"><h3>{{getCountdown}}</h3></div>
                <div class="text-center"><h2>Soal Ke {{no}}</h2></div>
                <div v-html="pertanyaan"></div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                        <div class="custom-control custom-radio" v-for="(pil, index) in pilihan">
                          <input class="custom-control-input" type="radio" v-model="jawab" :id="pil.no" :value="pil.no" name="customRadio">
                          <label :for="pil.no" class="custom-control-label" v-html="pil.jawaban"></label>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <br>
            <div class="row">
                <div class="col-6">
                     <router-link :disabled="loading" :to="{path:`/ujian-finish/${kode}`}" v-if="showFinish" class="btn btn-outline btn-lg btn-danger mr-3"><i class="fas fa-arrow-left"></i> Selesai</router-link>
                </div>
                <div class="col-6 text-right">
                    <button :disabled="loading" class="btn btn-secondary btn-large mr-2" v-if="enableSkip" @click="submit('skip')">Lewati</button>
                    <button :disabled="loading" class="btn btn-success btn-large" @click="submit('answer')">Jawab</button>
                </div>
        </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
    export default {
    name: 'SoalProcess',
    data() {
        return {
            kode: '',
            refid: '',
            loading: false,
            no: '',
            pertanyaan: '',
            countdown: 0,
            pilihan: [],
            showFinish: false,
            enableSkip: true,
            timeout: 0,
            jawab:0
        };
    },
    created: function () {
        let id = this.$route.params.id;
        this.kode = id;
        this.loadSoal(id);

    },
    methods: {
        loadSoal(id){
            this.loading = true;
            authFetch("/siswa/test/soal/" + id).then((res) => {
                res.json().then((json) => {
                    console.log(json);
                    if(json.success)
                    {
                        this.loading = false;
                        if(json.expired)this.$router.push("/ujian-finish/" + this.kode);
                        else{
                            if(!json.soal)
                            {
                                this.$router.push("/ujian-finish/" + this.kode);
                                return;   
                            }
                            this.pertanyaan = json.soal.soal;
                            this.pilihan = json.pilihan;
                            this.no = json.soal.nomor_soal;
                            this.timeout = json.timeout;
                            this.refid = json.refid;
                            setInterval(() => {
                                this.countdown = this.timeout - parseInt((new Date()).getTime()/1000) 
                            }, 1000);

                        }
                    }
                    else{
                        this.$router.push("/ujian-finish/" + this.kode);
                    }
                });
            });
        },
        submit: function(type)
        {
            var formData = {
                'kode' : this.kode,
                'no_soal': this.no,
                'jawab' : this.jawab,
                'ref_id' : this.refid,
                'submitType' : type

            };
            if(type=='answer' && !this.jawab)
            {
                return;
            }
            this.loading = true;
            var data = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
            authFetch('/siswa/test/submit', {
                method: 'POST',
                body: data
            })
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(json => {
                this.loading = false;
                if(json.success)
                {
                    this.jawab = '';
                    if(json.data.tampilSemua)this.showFinish=true;
                    if(json.data.sisa<=1)this.enableSkip = false;
                    if(json.data.expired || json.data.endpage )this.$router.push("/ujian-finish/" + this.kode);
                    else{
                        if(json.data.sisa==0)
                        {
                            this.$router.push("/ujian-finish/" + this.kode)
                        }
                        else{
                            this.loadSoal(this.kode);
                        }
                    }
                    //this.$router.push("/ujian-process/" + this.kode);
                }
            });
        }
    },
    computed:{
        getCountdown: function(){
            var mnt = parseInt(this.countdown/60);
            var dtk = this.countdown%60;
            mnt = mnt > 9 ? mnt : ('0'+mnt);
            dtk = dtk > 9 ? dtk : ('0'+dtk);
            return this.countdown < 0 ? '-': (mnt+':'+dtk);
        }
    }
}
</script>
<style type="text/css" scoped>
</style>